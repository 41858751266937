import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpFormComponent } from './otp-form.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { TranslateModule } from '@ngx-translate/core';
import { SafeModule } from '@app/shared/pipes';
import { LayoutDirectionModule } from '@app/shared/directives';

@NgModule({
  declarations: [OtpFormComponent],
  imports: [CommonModule, NgOtpInputModule, TranslateModule, SafeModule, LayoutDirectionModule],
  exports: [OtpFormComponent],
})
export class OtpFormModule {}
