export enum CertifiedContractorServiceStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
}

export enum PriceQuotationStatuses {
  Draft = 'draft',
  UnderContractorReview = 'under_contractor_review',
  RejectedByContractor = 'rejected_by_contractor',
  PriceQuotationUnderProcess = 'price_quotation_under_process',
  RejectedByBeneficiary = 'rejected_by_beneficiary',
  UnderBeneficiaryReview = 'under_beneficiary_review',
  Expired = 'expired',
  AcceptedByBeneficiary = 'accepted_by_beneficiary',
  PendingForContract = 'pending_for_contract',
  Contracted = 'contracted',
}
