export enum RegisterActionType {
  VERIFY_ACCOUNT = 'verify_account',
  VERIFY_ABSHER_OTP = 'verify_absher_otp',
  INFORMATION_FORM = 'information_form',
  CREATE_PASSWORD = 'create_password',
  VERIFY_SAKANI_OTP = 'verify_sakani_otp',
  REGISTER_SUCCESS = 'register_success',
}

export enum ExternalRegisterActionType {
  INFORMATION_FORM = 'information_form',
  CREATE_PASSWORD = 'create_password',
  REGISTER_SUCCESS = 'register_success',
}
