import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipatedBanksComponent } from './participated-banks.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ParticipatedBanksComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ParticipatedBanksComponent],
})
export class ParticipatedBanksModule {}
