export enum AuthenticationTenancySearchEnum {
  CONTRACT_ID = 'contract_id',
  LESSOR_NAME = 'lessor_name',
  TENANT_NAME = 'tenant_name',
  STATUS = 'status',
}

export enum AuthenticationTenancyStateEnum {
  WAITING_PARTIES = 'waiting_parties',
  WAITING_LESSOR = 'waiting_for_lessor',
  WAITING_TENANT = 'waiting_for_tenant',
}

export enum AuthenticationContractPartyRoleEnum {
  TENANT = 'tenant',
  LESSOR = 'lessor',
  TENANT_REPRESENTATIVE = 'tenant_representative',
  LESSOR_REPRESENTATIVE = 'lessor_representative',
}

export enum TermAndConditionActionType {
  APPROVE_CONTRACT_WITH_BROKERAGE = 'approve_contract_with_brokerage_agreement',
  APPROVE_CONTRACT_WITH_OUT_BROKERAGE = 'approve_contract_without_brokerage_agreement',
}
