import { BottomSheetModalComponent } from './bottom-sheet-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutDirectionModule } from '@app/shared/directives';
import { SafeModule } from '@app/shared/pipes';

@NgModule({
  declarations: [BottomSheetModalComponent],
  imports: [CommonModule, NgbModalModule, SafeModule, TranslateModule, LayoutDirectionModule],
  exports: [BottomSheetModalComponent],
})
export class BottomSheetModalModule {}
