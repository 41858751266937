import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

function numberWithMonths(x: any) {
  return x.length > 0 ? formattedNumber(x) : x;
}

function formattedNumber(x: any) {
  return x.toString().replace(/ Months/g, '') + ' Months';
}

@Directive({
  selector: '[appInputMonth]',
})
export class InputMonthDirective implements AfterViewChecked {
  inputElement: HTMLInputElement;
  start: any = 0;
  end: any = 0;

  ngAfterViewChecked() {
    this.formatValue();
  }

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  private formatValue() {
    if (this.inputElement.value !== null) {
      this.inputElement.value = numberWithMonths(this.inputElement.value);
      this.inputElement.setSelectionRange(this.start, this.end);
    }
  }

  private unFormatValue() {
    const value = this.inputElement.value;
    if (value) {
      this.inputElement.value = value.replace(/[^\d]/g, '');
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    this.start = this.inputElement.selectionStart || 0;
    this.end = this.inputElement.selectionEnd || 0;
    this.inputElement.value = value.replace(/[^\d]/g, '');

    // trigger input event for validating the real input value
    if (this.inputElement.value.length === 0 && value != this.inputElement.value) {
      var event = new Event('input');
      this.inputElement.dispatchEvent(event);
    }
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(); // add commas
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue(); // remove commas for editing purpose
  }
}
