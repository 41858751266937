export enum RealEstateOrientation {
  EASTERN = 'eastern',
  WESTERN = 'western',
  NORTHERN = 'northern',
  SOUTHERN = 'southern',
  NORTH_EAST = 'north_east',
  NORTH_WEST = 'north_west',
  SOUTH_EAST = 'south_east',
  SOUTH_WEST = 'south_west',
  THREE_STREETS = 'three_streets',
  FOUR_STREETS = 'four_streets',
}
