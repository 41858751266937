import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

function numberWithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

@Directive({
  selector: '[appInputCurrency]',
})
export class InputCurrencyDirective implements AfterViewChecked {
  inputElement: HTMLInputElement;

  ngAfterViewChecked() {
    this.formatValue();
  }

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  private formatValue() {
    if (this.inputElement.value !== null) {
      this.inputElement.value = numberWithCommas(this.inputElement.value);
    }
  }

  private unFormatValue() {
    const value = this.inputElement.value;
    if (value) {
      this.inputElement.value = value.replace(/[^\d.-]/g, '');
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    this.inputElement.value = value.replace(/[^\d.-]/g, '');
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(); // add commas
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue(); // remove commas for editing purpose
  }
}
