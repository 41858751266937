import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs.component';
import { TabContentHostDirective } from './tab-content-host.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TabsComponent, TabContentHostDirective],
  imports: [CommonModule, TranslateModule],
  exports: [TabsComponent, TabContentHostDirective],
})
export class TabsModule {}
