import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectUnitCardInformationComponent } from './project-unit-card-information.component';
import { TranslateModule } from '@ngx-translate/core';
import { MinMaxRangeModule } from '@app/shared/pipes';
import { LayoutDirectionModule } from '@app/shared/directives';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [ProjectUnitCardInformationComponent],
  imports: [CommonModule, TranslateModule, MinMaxRangeModule, LayoutDirectionModule, SharedModule],
  exports: [ProjectUnitCardInformationComponent],
})
export class ProjectUnitCardInformationModule {}
