export enum AuthenticationType {
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot_password',
}

export enum CommunicationActionType {
  COMMUNICATION_FORM = 'communication_form',
  VERIFY_SAKANI_OTP = 'verify_sakani_otp',
  SUCCESS = 'success',
}
