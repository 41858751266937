import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionSelectorComponent } from './region-selector.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RegionSelectorComponent],
  imports: [CommonModule, DropdownModule, FormsModule],
  exports: [RegionSelectorComponent],
})
export class RegionSelectorModule {}
