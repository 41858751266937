export enum EventTrackingActivity {
  BANNER_BOOKING_CANCEL = 'BANNER_BOOKING_CANCEL',
  BLOCKED_BY = 'BLOCKED_BY',
  BOOKING_FAIL = 'BOOKING_FAIL',
  BOOKING_SUCCESS = 'BOOKING_SUCCESS',
  BOOKING_SUMMARY_BACK_CLICKED = 'BOOKING_SUMMARY_BACK_CLICKED',
  BOOKING_SUMMARY_BACK_CLICKED_CONFIRM = 'BOOKING_SUMMARY_BACK_CLICKED_CONFIRM',
  BOOKING_SUMMARY_CONFIRM = 'BOOKING_SUMMARY_CONFIRM',
  BOOKING_SUMMARY_CONFIRM_RESULT = 'BOOKING_SUMMARY_CONFIRM_RESULT',
  CANCELLATION_REQUEST_CONFIRM_CANCEL = 'CANCELLATION_REQUEST_CONFIRM_CANCEL',
  CANCEL_BOOKING_FROM_MY_BOOKING = 'CANCEL_BOOKING_FROM_MY_BOOKING',
  DESIGN_RESERVE_CLICKED = 'DESIGN_RESERVE_CLICKED',
  GOOGLE_MAP_ICON_CLICKED = 'GOOGLE_MAP_ICON_CLICKED',
  JOIN_QUEUE_RESULT = 'JOIN_QUEUE_RESULT',
  JOIN_QUEUE_RESULT_FAIL = 'JOIN_QUEUE_RESULT_FAIL',
  JOIN_QUEUE_RESULT_SUCCESS = 'JOIN_QUEUE_RESULT_SUCCESS',
  LEAVE_QUEUE_RESULT = 'LEAVE_QUEUE_RESULT',
  MARKETPLACE_INDEX_PAGE_FILTER_QUERY_PARAMS = 'MARKETPLACE_INDEX_PAGE_FILTER_QUERY_PARAMS',
  MARKETPLACE_LOAD_MORE_CLICKED = 'MARKETPLACE_LOAD_MORE_CLICKED',
  MARKETPLACE_LOG_QUERY = 'MARKETPLACE_LOG_QUERY',
  OFFPLAN_BOOKING_CANCEL = 'OFFPLAN_BOOKING_CANCEL',
  OFFPLAN_BOOKING_CONFIRM = 'OFFPLAN_BOOKING_CONFIRM',
  OFFPLAN_CONTINUE_PAY_BOOKING_FEE = 'OFFPLAN_CONTINUE_PAY_BOOKING_FEE',
  PAYMENT_FAIL = 'PAYMENT_FAIL',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PROCEED_BOOKING = 'PROCEED_BOOKING',
  PROJECT_CARD_MISCLICK = 'PROJECT_CARD_MISCLICK',
  PROJECT_UNIT_CARD_INFORMATION_CLICKED = 'PROJECT_UNIT_CARD_INFORMATION_CLICKED',
  PROJECT_UNIT_FILTER_CHANGES_EVENT = 'PROJECT_UNIT_FILTER_CHANGES_EVENT',
  PROJECT_VIEW_BROWSE_MORE_BUTTON_CLICKED = 'PROJECT_VIEW_BROWSE_MORE_BUTTON_CLICKED',
  PROJECT_VIEW_GOOGLE_MAP_ICON_CLICKED = 'PROJECT_VIEW_GOOGLE_MAP_ICON_CLICKED',
  RESERVATION_CLICKED_BUT_NEED_LOGIN = 'RESERVATION_CLICKED_BUT_NEED_LOGIN',
  RESERVED_GENERAL_RESULT = 'RESERVED_GENERAL_RESULT',
  RESERVED_LAND_RESULT = 'RESERVED_LAND_RESULT',
  RESERVING = 'RESERVING',
  SHARE_BUTTON_CLICKED = 'SHARE_BUTTON_CLICKED',
  SHOW_MASTER_PLAN_CLICKED = 'SHOW_MASTER_PLAN_CLICKED',
  TOGGLE_ACCORDION = 'TOGGLE_ACCORDION',
  UNIT_LIST_LOAD_MORE_CLICKED = 'UNIT_LIST_LOAD_MORE_CLICKED',
  UNIT_START_BOOKING_CLICKED_RESULT = 'UNIT_START_BOOKING_CLICKED_RESULT',
  AZM_PAYMENT_CALLBACK_SUCCESS = 'AZM_PAYMENT_CALLBACK_SUCCESS',
  AZM_PAYMENT_CALLBACK_FAIL = 'AZM_PAYMENT_CALLBACK_FAIL',
}
