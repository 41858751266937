export enum LandBannerMessageCases {
  MAIN_JOURNEY = 'mainJourney',
  OUT_OF_JOURNEY = 'outOfJourney',
  EXPIRED_OUT_JOURNEY = 'expiredOutJourney',
  EXPIRED_IN_JOURNEY = 'expiredInJourney',
  QUEUE_IN_JOURNEY = 'queueInJourney',
  QUEUE_OUT_JOURNEY = 'queueOutJourney',
  ONLY_MOBILE_CAN_RESERVE = 'onlyMobileCanReserve',
  FULLY_BOOKED = 'fullyBooked',
}
