import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MarketUnitCardComponent } from './market-unit-card.component';
import { BadgeModule } from '../badge/badge.module';
import { ImageGallerySliderModule } from '../image-gallery-slider/image-gallery-slider.module';
import { TranslateModule } from '@ngx-translate/core';
import { RegionCityNameModule } from '@app/shared/pipes';
import { LayoutDirectionModule } from '@app/shared/directives';

@NgModule({
  declarations: [MarketUnitCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImageGallerySliderModule,
    BadgeModule,
    TranslateModule,
    RegionCityNameModule,
    LayoutDirectionModule,
  ],
  exports: [MarketUnitCardComponent],
})
export class MarketUnitCardModule {}
