import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionNameById } from './region-name-by-id.pipe';

@NgModule({
  declarations: [RegionNameById],
  imports: [CommonModule],
  exports: [RegionNameById],
})
export class RegionNameByIdModule {}
