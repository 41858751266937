export enum BookingCancelReasons {
  PROJECT_LOCATION = 'project_location',
  PROJECT_UNIT_DESIGN = 'project_unit_design',
  UNIT_PRICE = 'unit_price',
  UNIT_AREA = 'unit_area',
  DEALLOCATION = 'deallocation',
  REJECTED_BY_BANK = 'rejected_by_bank',
  UNSIGNED_CONTRACT = 'unsigned_contract',
  OTHERS = 'others',
  UNIT_DETAILS = 'unit_details',
  NOT_READY_FOR_LENDING = 'not_ready_for_lending',
  UNIT_LOCATION = 'unit_location',
}
