import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CitySelectorComponent } from './city-selector.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from '../dropdown/dropdown.module';

@NgModule({
  declarations: [CitySelectorComponent],
  imports: [CommonModule, DropdownModule, FormsModule],
  exports: [CitySelectorComponent],
})
export class CitySelectorModule {}
