export enum MarketingRentalUnitType {
  FLOOR = 'floor',
  OTHER = 'other',
  APARTMENT = 'apartment',
  VILLA = 'villa',
  BUILDING = 'building',
  TOWER = 'tower',
  DUPLEX = 'duplex',
  STUDIO = 'studio',
  SECONDARY_APARTMENT = 'secondary_apartment',
  APARTMENT_EXTENSION = 'apartment_extension',
  FLOOR_EXTENSION = 'floor_extension',
  PENTHOUSE_VILLA = 'penthouse_villa',
  DRIVERS_ROOM = 'drivers_room',
}
