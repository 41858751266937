import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterWaitingListComponent } from './register-waiting-list.component';
import { ErrorsListModule, SafeModule } from '@app/shared/pipes';
import { RadioModule } from '../radio/radio.module';
import { CollapseBoxModule } from '../collapse-box/collapse-box.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [RegisterWaitingListComponent],
  imports: [CommonModule, SharedModule, TranslateModule, ErrorsListModule, RadioModule, CollapseBoxModule, SafeModule],
  exports: [RegisterWaitingListComponent],
})
export class RegisterWaitingListModule {}
