export enum FilterTagKey {
  BASEMENT = 'basement',
  ELEVATOR = 'elevator',
  POOL = 'pool',
  MAID_ROOM = 'maid_room',
  DRIVER_ROOM = 'driver_room',
  MIN_AREA = 'min_area',
  MAX_AREA = 'max_area',
  MAIN_STREET = 'main_street',
  ROOMS = 'rooms',
  MAX_PRICE = 'max_price',
  UNIT_AGE = 'unit_age',
  REGION_ID = 'region_id',
  CITY_ID = 'city_id',
  LAND_TYPES = 'land_types',
  UNIT_CONSTRUCTION_STATUS = 'unit_construction_status',
  UNIT_TYPE = 'unit_type',
  MAIN_BUILDING_FACE = 'main_building_face',
  AVAILABLE_UNITS_FOR_AUCTIONS_COUNT = 'available_units_for_auctions_count',
  SWIMMING_POOL = 'swimming_pool',
  STORAGE_AREA = 'storage_area',
  BALCONY = 'balcony',
  NANNY_ROOM = 'nanny_room',
  NUMBER_OF_BEDROOMS__IN = 'number_of_bedrooms__in',
  NUMBER_OF_BATHROOMS__IN = 'number_of_bathrooms__in',
  NUMBER_OF_FLOORS__IN = 'number_of_floors__in',
  SPACE__MAX = 'space__max',
  SPACE__MIN = 'space__min',
  FIT_LAND_AREA = 'fit_land_area',
  BUILDING_CODE_ID = 'building_code_id',
  DESIGNER_ID = 'designer_id',
}
