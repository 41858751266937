export enum YourOwnDesignStatus {
  INITIAL = 'initial',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  ACTIVE = 'active',
}

export enum YourOwnDesignInvoiceStatus {
  UNKNOWN = 'unknown',
  PAID = 'paid',
  EXPIRED = 'expired',
  UNPAID = 'not_paid',
  PENDING = 'pending',
}
