import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutDirectionModule } from '@app/shared/directives';
import { DisplayDataBoxComponent } from './display-data-box.component';
import { PercentageModule } from '@app/shared/pipes';

@NgModule({
  declarations: [DisplayDataBoxComponent],
  imports: [CommonModule, TranslateModule, LayoutDirectionModule, PercentageModule],
  exports: [DisplayDataBoxComponent],
})
export class DisplayDataBoxModule {}
