import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

function numberWithCommas(x: any) {
  return x.length > 0 ? formattedNumber(x) : x;
}

function formattedNumber(x: any) {
  return (
    x
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/ SAR/g, '') + ' SAR'
  );
}

@Directive({
  selector: '[appInputSarCurrency]',
})
export class InputSarCurrencyDirective implements AfterViewChecked {
  inputElement: HTMLInputElement;
  start: any = 0;
  end: any = 0;
  comma_count_before_formatted: any = 0;

  ngAfterViewChecked() {
    this.formatValue();
  }

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  private formatValue() {
    if (this.inputElement.value !== null) {
      this.inputElement.value = numberWithCommas(this.inputElement.value);
      this.customCaret();
    }
  }

  private unFormatValue() {
    const value = this.inputElement.value;
    if (value) {
      this.inputElement.value = value.replace(/[^\d.]/g, '');
    }
  }

  private customCaret() {
    const comma_count_after_formatted = (this.inputElement.value.match(/,/g) || []).length;

    // this.end > 0, current caret is stay before the frist char
    if (this.end > 0 && this.comma_count_before_formatted !== comma_count_after_formatted) {
      if (this.comma_count_before_formatted < comma_count_after_formatted) {
        this.inputElement.setSelectionRange(this.start + 1, this.end + 1);
      } else {
        this.inputElement.setSelectionRange(this.start - 1, this.end - 1);
      }
    } else {
      this.inputElement.setSelectionRange(this.start, this.end);
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    this.start = this.inputElement.selectionStart || 0;
    this.end = this.inputElement.selectionEnd || 0;
    this.comma_count_before_formatted = (value.match(/,/g) || []).length;
    this.inputElement.value = value.replace(/[^\d.]/g, '');

    // trigger input event for validating the real input value
    if (this.inputElement.value.length === 0 && value != this.inputElement.value) {
      var event = new Event('input');
      this.inputElement.dispatchEvent(event);
    }
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(); // add commas
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue(); // remove commas for editing purpose
  }
}
