import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputComponent } from './chip-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ChipInputComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  exports: [ChipInputComponent],
})
export class ChipInputModule {}
