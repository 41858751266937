// TODO: check these roles that they are correct, this is pretty much place holder
export enum UserRole {
  Beneficiary = 'beneficiary',
  Admin = 'admin',
  SuperAdmin = 'super_admin',
  Developer = 'developer',
  SalesAdmin = 'sales_admin',
  SalesReps = 'sales',
  MediaAdmin = 'media_admin',
  BusinessOwner = 'business_owner',
  BackOffice = 'back_office',
  AligibilityAdmin = 'eligibility_admin',
  ProductAuthorizedUser = 'product_authorized_user',
  RelationManager = 'relation_manager',
}
