export enum VoucherType {
  ONLINE = 'online',
  BRANCH = 'branch',
  BOTH = 'both',
}

export enum VoucherPublishStatus {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  EXPIRED = 'expired',
}

export enum VoucherTargetingScope {
  ALL_BENEFICIARIES = 'all_beneficiaries',
  SPECIFIC_TARGETED_GROUPS = 'specific_targeted_groups',
}

export enum VoucherCodeType {
  COUPON_CODE = 'coupon_code',
  QR_CODE = 'qr_code',
}

export enum VoucherCategory {
  FURNITURE = 'furniture',
  ELECTRICAL_DEVICE = 'electrical_device',
  CONSTRUCTION_MATERIALS = 'construction_materials',
  HOME_CARE = 'home_care',
  OTHER = 'other',
}
