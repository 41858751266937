import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleUploadComponent } from './multiple-upload.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SafeModule } from '@app/shared/pipes';

@NgModule({
  declarations: [MultipleUploadComponent],
  imports: [CommonModule, FormsModule, TranslateModule, SafeModule],
  exports: [MultipleUploadComponent],
})
export class MultipleUploadModule {}
