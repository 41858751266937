import { CheckboxModule } from '@app/shared/components';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemsComponent } from './list-items.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LayoutDirectionModule } from '@app/shared/directives';

@NgModule({
  declarations: [ListItemsComponent],
  imports: [CommonModule, TranslateModule, FormsModule, NgSelectModule, LayoutDirectionModule, CheckboxModule],
  exports: [ListItemsComponent],
})
export class ListItemsModule {}
