import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HijriDatepickerComponent } from './hijri-datepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutDirectionModule } from '@app/shared/directives';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
  declarations: [HijriDatepickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutDirectionModule,
    NgbDatepickerModule,
    TranslateModule,
    NgxMaskDirective,
  ],
  exports: [HijriDatepickerComponent],
})
export class HijriDatepickerModule {}
