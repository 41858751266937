import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingFormComponent } from './rating-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChipInputModule } from '@app/shared/components/chip-input/chip-input.module';

@NgModule({
  declarations: [RatingFormComponent],
  imports: [CommonModule, FormsModule, TranslateModule, ChipInputModule, ReactiveFormsModule],
  exports: [RatingFormComponent],
})
export class RatingFormModule {}
