import { ObjKeysPipe } from './obj-keys.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ObjKeysPipe],
  imports: [CommonModule],
  exports: [ObjKeysPipe],
})
export class ObjKeysModule {}
