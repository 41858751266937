import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupUnitCardComponent } from './group-unit-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { MinMaxRangeModule, RegionCityNameModule } from '@app/shared/pipes';
import { ImageGallerySliderModule } from '../image-gallery-slider/image-gallery-slider.module';
import { BadgeModule } from '../badge/badge.module';
import { RouterModule } from '@angular/router';
import { LayoutDirectionModule } from '@app/shared/directives';

@NgModule({
  declarations: [GroupUnitCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    BadgeModule,
    TranslateModule,
    ImageGallerySliderModule,
    RegionCityNameModule,
    MinMaxRangeModule,
    LayoutDirectionModule,
  ],
  exports: [GroupUnitCardComponent],
})
export class GroupUnitCardModule {}
