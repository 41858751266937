import { BeneficiaryApplicationStatus } from '@app/enums';

// This model is used to parsed the user data from valid token
export class User {
  constructor(
    public user_id?: string,
    public cr_number?: string,
    public username?: string,
    public first_name?: string,
    public firstName?: string,
    public last_name?: string,
    public lastName?: string,
    public project_type_scope?: string,
    public role?: string,
    public permission?: any,
    public activation_status?: string,
    public name?: string,
    public iam_session_index?: string,
    public national_id_number?: string,
    public application_status?: BeneficiaryApplicationStatus,
    public active?: boolean,
    public title_ar?: string,
    public title_en?: string,
    public profileType?: string,
    public last_login_date?: Date,
    public preferred_products?: string[],
    public preferred_region_id?: number,
    public preferred_city_id?: number,
    public preferred_unit_types?: string[],
    public viewed_marketplace_onboarding?: string,
    public email_address?: string
  ) {}
}
