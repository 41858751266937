import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownBottomSheetComponent } from './dropdown-bottom-sheet.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LayoutDirectionModule } from '@app/shared/directives';
import { ListItemsModule } from '../list-items/list-items.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { BottomSheetModalModule } from '../bottom-sheet-modal/bottom-sheet-modal.module';

@NgModule({
  declarations: [DropdownBottomSheetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgSelectModule,
    LayoutDirectionModule,
    ListItemsModule,
    CheckboxModule,
    BottomSheetModalModule,
  ],
  exports: [DropdownBottomSheetComponent],
})
export class DropdownBottomSheetModule {}
