export enum PriceQuotationStatus {
  ISSUED = 'issued',
  CANCELLED = 'cancelled',
  REJECTED_BY_BANK = 'rejected_by_bank',
  INQUIRED = 'inquired',
  ACCEPTED = 'accepted',
  PENDING_FOR_SIGN = 'pending_for_sign',
  SIGNED = 'signed',
  CANCELLED_CONTRACT = 'cancelled_contract',
  EXPIRED = 'expired',
  EXPIRED_INQUIRY = 'expired_inquiry',
  NOT_ELIGIBLE = 'not_eligible',
}
