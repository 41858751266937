export enum ErrorKey {
  INVALID_RECAPTCHA_TOKEN_ERROR = 'invalid_recaptcha_token_error',
  NATIONAL_ID_NUMBER_BLOCKED = 'national_id_number_blocked',
  SESSION_TIME_EXPIRED = 'session_time_expired',
  IAM_ERROR = 'iam_error',
  OTP_VERIFICATION_UNSUCCESSFULLY = 'otp_verification_unsuccessfully',
  REDIRECT_TO_IAM_MODE = 'redirect_to_iam_mode',
  CANNOT_CREATE_OTP = 'cannot_create_otp',
  CANNOT_COMPLETE_REGISTRATION = 'cannot_complete_registration',
  CANNOT_COMPLETE_CHECKOUT = 'cannot_complete_checkout',
  SERVER_SERVER_ERROR_500 = 'internal_server_error_500',
  SERVER_NOT_IMPLEMENTED_501 = 'server_not_implemented_501',
  SERVER_BAD_GATEWAY_502 = 'server_bad_gateway_502',
  SERVER_SERVICE_NOT_AVAILABLE_503 = 'server_service_not_available_503',
  SERVER_GATEWAY_TIMEOUT_504 = 'server_gateway_timeout_504',
  CAN_NOT_CREATE_OTP_ABSHER = 'cannot_create_otp_absher',
  CAN_NOT_CREATE_OTP_CHANGE_PASSWORD = 'cannot_create_otp_change_password',
}
