export enum OtpActionType {
  VERIFY_PHONE_NUMBER = 'verify_phone_number',
  BENEFICIARY_ABSHER = 'beneficiary_absher',
  BENEFICIARY_REGISTER = 'beneficiary_register',
  BENEFICIARY_RESET_PHONE = 'beneficiary_reset_phone',
  BENEFICIARY_LOGIN = 'beneficiary_login',
  RESET_PASSWORD = 'beneficiary_reset_password',
  SEND_AUTHCON_TENANCY_SERVICE_VIA_ABHSER = 'send_authcon_tenancy_service_via_abhser',
  RESEND_AUTHCON_TENANCY_SERVICE_VIA_ABHSER = 'resend_authcon_tenancy_service_via_abhser',
  WALLET_WITHDRAW = 'wallet_withdraw',
  UNIT_DELIVERY_REJECT = 'unit_delivery_reject',
  UNIT_DELIVERY_APPROVE = 'unit_delivery_approve',
}
